function getResultados(){
  var palabra = $('#palabaBuscador').val();
  var ajaxurl = "/wp-admin/admin-ajax.php";
  $('#loading').show();
  $('.cercant').show();
  $('.escriu').hide();
  /*$.post(ajaxurl,{ "action":"get_buscador", "palabra" : palabra },function(data){
    $('#loading').hide();
    $('.cercant').hide();
    $('.resultadosBuscador').html(data.html);
  },"json");*/
  ajaxurl = "/";
  $.get(ajaxurl,{ "s" : palabra },function(data){
    $('#loading').hide();
    $('.cercant').hide();
    $('.resultadosBuscador').html(data.html);
  },"json");

}

$(document).ready(function(){
  $('.loading').addClass('loaded');

  //OPEN SUPERMENU
  $('#openMenu').on('click',function(){
    $('.nav__supermenu').fadeIn('slow');
  });
  $('#closeMenu').on('click',function(){
    $('.nav__supermenu').fadeOut('slow');
  });

  //OPEN MOBILEMENU
  $('#openMobileMenu').on('click',function(){
    $('.nav__mobilemenu').addClass('open');
  });
  $('#closeMobileMenu').on('click',function(){
    $('.nav__mobilemenu').removeClass('open');
  });

  //OPEN SIDEBAR MOBILE
  $('.sidebar__open').on('click',function(){
    $(this).css('display','none');
    $('.sidebar').addClass('open');
    $('.sidebar__close').css('display','block');
  });
  $('.sidebar__close').on('click',function(){
    $(this).css('display','none');
    $('.sidebar__open').css('display','block');
    $('.sidebar').removeClass('open');

  });

  //ICONOS BENVINGUTS IRTA
  $(".benvinguts-irta a").mouseover(function() {
    $(".benvinguts-irta a").css('opacity','0.5');
    $(this).css('opacity','1');
  }).mouseout(function(){
    $(".benvinguts-irta a").css('opacity','1');
  });

  //


$(function(){
    /* activate jquery isotope */
    $('.isotopeDiv').imagesLoaded( function(){
      $('.isotopeDiv').isotope({
        itemSelector : '.itemIso'
      });
    });
    $(window).smartresize(function(){
      $('.isotopeDiv').isotope({
        columnWidth: '.col-sm-3'
      });
    });
})





  //OPEN SEARCH

  $('#openSearch').on('click',function(){
    $('.search').fadeIn('slow',function(){
      $('#palabaBuscador').focus();
    });
    $('.autocomplete-suggestions').css('display','block');
    return false;
  });

  $(document).on("click","#closeSearch",function(){
    $('.search').fadeOut("slow");
  })

  /*$('.search form').on('mouseout',function(){

    $('.search').on('click',function(){
      $('.search').fadeOut('slow');
    });

    return false;
  });*/


  function parseColor(color) {
      var arr=[]; color.replace(/[\d+\.]+/g, function(v) { arr.push(parseFloat(v)); });
      return {
          hex: "#" + arr.slice(0, 3).map(toHex).join(""),
          opacity: arr.length == 4 ? arr[3] : 1
      };
  }
  function toHex(int) {
      var hex = int.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
  }


  function hoverIn(colorHex,callback){
    var widthWindow = $(window).width();
    if(backgroundProgress2==""){
      $('.nav__mainmenu__progressbar').css({ "background" : colorHex });
    }
    $('.nav__mainmenu__progressbar').stop();
    $('.nav__mainmenu__progressbar').animate({"left":"0px"},1000,function(){
      callback();
    });
  }

  function hoverOut(callback){
    $('.nav__mainmenu__progressbar').stop();
    $('.nav__mainmenu__progressbar').animate({"left":"100%"},700,function(){
      callback();
    });
  }
  $(document).on("click",".search",function(){
    $('.search').fadeOut("slow");
  }).on("click",".search .container",function(e){
    e.stopPropagation();
  });

  $('#palabaBuscador').keyup(function(){
    $('#sbuscador').val($(this).val());
  })

  var backgroundProgress = "";
  var backgroundProgress2 = "";
  var complete = false;
  $('.nav__mainmenu__item').hover(function(){
    $('.nav__mainmenu__item').removeClass("active");
    $(this).addClass("active");
    $('.nav__mainmenu__item:not(.active)').addClass("gris");
    var color = $(this).css("color");
    var colorToHex = parseColor(color);
    var colorHex = colorToHex.hex;
    console.log("hoverin");
    hoverIn(colorHex,function(){
    });
    backgroundProgress2 = colorHex;
    if(backgroundProgress!=colorHex && backgroundProgress!="" && !complete){
      console.log("hoverout");
      hoverOut(function(){
        $('.nav__mainmenu__progressbar').css({ "background" : colorHex });
        hoverIn(colorHex,function(){
        });
        backgroundProgress2 = colorHex;
        complete = true;
      })
    }
    backgroundProgress = colorHex;
  },function(){
    complete = false;
    $('.nav__mainmenu__item').removeClass("gris");
    $('.nav__mainmenu__item').removeClass("active");
    hoverOut(function(){
      backgroundProgress2 = "";
      complete = true;
    });
  });

  //OPEN LANG SELECTOR
  $('#selectLang').on('click',function(){
    if($('.header__lang__opt').hasClass('open')==true){
      $('.header__lang__opt').removeClass('open');
      $('.header__lang__selector').removeClass('open');
    }else{
      $('.header__lang__selector').addClass('open');
      $('.header__lang__opt').addClass('open');
    }


  });


  //FOOTER
  $('.footer__link__location').on('click', function(){
    $('.footer__top__top__location').show('slow');
    $('.footer__top__top__info').hide('slow');
    $('.footer__top__top__newsletter').hide('slow');
  });
  $('.footer__link__info').on('click', function(){
    $('.footer__top__top__info').show('slow');
    $('.footer__top__top__location').hide('slow');
    $('.footer__top__top__newsletter').hide('slow');
  });
  $('.footer__link__newsletter').on('click', function(){
    $('.footer__top__top__newsletter').show('slow');
    $('.footer__top__top__location').hide('slow');
    $('.footer__top__top__info').hide('slow');
  });



  //CAROUSEL
  $('#carouselServeis').slidermolon({
  		opacity_inactive: 0.2,
  		time_change_photo: 10000,
      bullets : false,
      width: 1120,
      width_2: 900
  });

  //SLIDER HOME
  /*$('#slideFotos').slidermolon({
  		opacity_inactive: 0.2,
  		time_change_photo: 10000,
      width: 800

  });*/

  //SLIDER QUI SOM
  $('#slideFotosQuiSom').slidermolon({
      opacity_inactive: 0.2,
      time_change_photo: 10000,
      buttons_nextprev: false
  });

  //SLIDER SECTION
  $('#slideSection').slidermolon({
      opacity_inactive: 0.2,
      time_change_photo: 10000,
      bullets : false,
      width: 285

  });


  //CHECKBOX
  $('#checkbox-ia').checkboxradio();
  $('#checkbox-a').checkboxradio();
  $('#checkbox-ra').checkboxradio();
  $('#checkbox-ma').checkboxradio();

  //DATEPICKER
  $('#fechaDesde').datepicker();
  $('#fechaHasta').datepicker();

  //SELECTMENU
  $('#ordenar').selectmenu();


  //SELECTMENU - IRTA - Directori Experts
  $('#programa,#tema_id').selectmenu();
  $('#subprograma').selectmenu();
  $('#centre').selectmenu();
  $('#categoria').selectmenu();

  //SELECTMENU - IRTA - Licitacions
  $('#tipus').selectmenu();

  //SELECTMENU - IRTA - Contactar
  $('#centre').selectmenu();

  //AUTOCOMPLETE SEARCH
  $('#searchIrta').autoComplete({
      minChars: 1,
      source: function(term, suggest){
          term = term.toLowerCase();
          var choices = ['fruita', 'fruita dolça', 'fruites dolces', 'fruita seca'];
          var suggestions = [];
          for (i=0;i<choices.length;i++)
              if (~choices[i].toLowerCase().indexOf(term)) suggestions.push(choices[i]);
          suggest(suggestions);
      }
  }

);





  //window.addEventListener('resize',function(){
  var vpWidth, vpHeight;
  vpWidth = window.innerWidth;
  vpHeight = window.innerHeight;

    $('#openMenu').on('click',function(){
      if(vpWidth <= 991){
        console.log("mobile");
     }else {
       console.log("desktop");
     }
    });
   //  })


});

$(window).scroll(function(){
  if(($('html,body').scrollTop()>228)||($("body").scrollTop()>228)){
    $('.header').addClass('sticky');
    setTimeout(anim, 75);
    function anim(){
      $('.header.sticky').addClass('on');
    }
  }else{
    $('.header').removeClass('sticky');
    $('.header').removeClass('on');
  }

});


$(document).on("click",".video__wrapper",function(){
  var height = $('.video__wrapper').height();
  var embed = $(this).attr("data-embed");
  $(this).closest(".module__content").find('.videoIframe').attr("src",embed+'?autoplay=1');
  $(this).hide();
  $(this).closest(".module__content").find('.videoIframe').css({"height":height+"px"});
  $(this).closest(".module__content").find('.videoIframe').show();
})


function getTags(){
  $('#tags_input').select2();
}

$(function(){
  getTags();
  $('.ordenar_filtro,#centre,#categoria,#tipus,#tema_id,#programa').on("selectmenuchange",function(){
    console.log("E");
    $(this).closest("form").submit();
  })
  $('#fechaDesde,#fechaHasta').on("change",function(){
    $(this).closest("form").submit();
  })
})


$(document).on("click",".addTag",function(){
  $('#tags_input').select2('open');
}).on("click",".removeAllTags",function(){
  $('#tags_input').val(null).trigger("change");
}).on("change",'#tags_input',function(){
  $(this).closest("form").submit();
})


function enviarMailChimp(){
  var ajaxurl = "/wp-admin/admin-ajax.php";
  var email = $('#email').val();
  var nom = $('#nom').val();
  var cognoms = $('#cognoms').val();
  var seg = $('#seg').val();
  var empresa = $('#empresa').val();
  var ambits = [];
  $('input[name="ambit"]:checked').each(function() {
      ambits.push($(this).val());
  });
  $('.newsletter__input--button input').prop("disabled",true);
  $('.newsletter__content_cargando').show();
  $.post(ajaxurl,{ "action":"send_mailchimp", email : email, nom : nom, cognoms : cognoms, empresa : empresa, ambits : ambits },function(data){
    $('.newsletter__content').slideUp();
    $('.newsletter__content_cargando').hide();
    $('.newsletter__content_ok').show();
    $('.newsletter__input--button input').prop("disabled",false);
  },"json");
}

function comprobarCamposFormulario(){
  var resultado = true;
  $('.form-campo').removeClass("hayError");
  $('.obli').each(function(){
    var valor = $(this).val();
    valor = valor.trim();
    if(valor==""){
      resultado = false;
      $(this).closest(".form-campo").addClass("hayError");
    }
  })
  return resultado;
}

function enviarFormulario(){
  var ajaxurl = "/wp-admin/admin-ajax.php";

  var nombre = $('#form_nom').val();
  var email = $('#form_email').val();
  var mensaje = $('#form_mensaje').val();
  var per = $('.formEnviar').attr("data-per");
  var page = $('.page').attr("data-page");
  var seg = $('#seg').val();
  $('.contacte_item input[type="submit"]').attr("prop",false);
  $.post(ajaxurl,{ "action":"send_formemail", nombre : nombre, email:email, mensaje:mensaje, seg:seg, per:per, page:page },function(data){
    $('.contacte_item form').slideUp();
    if(data.code==1){
      $('.contacte_item .msgOK').show();
    }else{
      $('.contacte_item .msgKO').show();
    }
  },"json");
}

$(document).on("click",".moreinfoPersona",function(){
      $('.contacte_item').slideToggle();
})

function anadirSpanPrensa(){
  $('.campaign').each(function(){
    var valor = $(this).html();
    $(this).html(valor.replace("-",""));

  })
}
