(function($){
 $.fn.slidermolon = function(options) {
  var defaults = {
   width: 940,
   width_lg: 0,
   width_md: 940,
   width_768 : 820,
   active_class: "actual",
   inactive_class: "",
   time_change_photo: 500,
   opacity_inactive: 0.3,
   buttons_nextprev: true,
   bullets:true,
   mask: false,
   startTime : true
  };
  var options = $.extend(defaults, options);
  var interval_time = "";
  var widthFinal = "";
  return this.each(function() {
	  var self=$(this);
	  window_resize();
	  getMedidas();
	  //EN CASO DE QUE buttons_netprev == true pintamos los botones y generamos sus eventos.
	  if(options.buttons_nextprev==true) {
	  	self.parent().append('<a href="javascript:void(0)" class="left carousel-control hidden-xs hidden-sm "><i class="icon i-i-06"></i><span class="text-hide">Anterior</span></a><a href="javascript:void(0)" class="right carousel-control hidden-xs hidden-sm"><i class="icon i-i-04"></i><span class="text-hide">Siguiente</span></a>');
		  self.parent().find('.carousel-control.right').click(function(){
			  if(self.children(".slide:eq(1)").position().left==0)
			  {
				  setTimerSlider(self);
				  nextimg(self);
			  }
		  });
		  self.parent().find('.carousel-control.left').click(function(){
			  //alert(self.children(".slide:eq(1)").position().left);
			  if(self.children(".slide:eq(1)").position().left==0)
			  {
				  setTimerSlider(self);
				  previmg(self);
			  }
		  });
	  }
	  if(options.mask==true)
		  self.css({"overflow":"hidden"});

	  if(options.bullets==true){
		  var str_bullets="";
		  var len_img=self.children(".slide").length;
		  for(var cont=0;cont<len_img;cont++){
			  str_bullets+="<li class='bullet' data-num='"+(cont+1)+"'><a href='javascript:void(0)' class='";
			  if(cont==1)
				  str_bullets+="active";

			  str_bullets+="'><span class='text-hide'>0"+(cont+1)+"</span></a></li>";
		  }
		  self.append('<ul class="slideButtons hidden-xs hidden-sm "><li><a href="#" class="playCarousel pararFotos"><span class="text-hide">Parar pasafotos</span></a></li>'+str_bullets+'</ul>');

		  $('.slideButtons .bullet a').click(function(){
			  if(self.children(".slide:eq(1)").position().left==0)
			  {
				  total_len = $('.slideButtons .bullet a').length;
				  total_num = $('.slideButtons .bullet a.active').index(".slideButtons .bullet a");
				  num_click = $(this).index(".slideButtons .bullet a");
				  resta_click = num_click-total_num;

				  var antes_num=1;
				  if(resta_click>0) {
					  resta_fin = resta_click;
				  }
				  else {
					  for(var cont = 0;cont<$('.slideButtons .bullet').length;cont++){
						  	//alert($(this).parent("li").attr("data-num")+" "+$('.slideButtons li:eq('+cont+')').attr("data-num"));
							  if($(this).parent(".bullet").attr("data-num")!=$('.slideButtons .bullet:eq('+cont+')').attr("data-num")){
									  antes_num++;
							  }
							  else{
								  break;
							  }
				  		}
					  resta_fin = (total_len-(total_num+1))+antes_num;//antes_num + ((total_len - antes_num)-1);

				  }
				  siguiente_redonda(resta_click);
				  nextximg(self,resta_fin);
			  }

		  })
	  }
	 	colocarImagenes();
	  setTimerSlider(self);
	  $(window).resize(function(){
	  	getMedidas();
	  })
	  function colocarImagenes(){

	  	 self.children(".slide:eq(1)").addClass(options.active_class);

	  	 if(self.parent().find('.caption').length>0){
				self.parent().find('.caption').removeClass("active");
				self.parent().find('.caption[data-num="'+self.children(".slide:eq(1)").attr("data-num")+'"]').addClass("active");
			}


		  var cont = 0;
		  self.find(".slide").each(function(){
			  $(this).css({"position":"absolute","opacity":options.opacity_inactive,"filter":"alpha(opacity="+options.opacity_inactive+")","left":(widthFinal*cont)-widthFinal});
			  cont++;
		  })
		  self.children(".slide:eq(1)").css({"opacity":"1"});
	  }

	  function getMedidas(){
	  	var widthWindow = $(window).width();

		/*if(widthWindow>2000){
	  		widthFinal = options.width_lg;
	  		colocarImagenes();
	  	}

      else{*/
	  		widthFinal = options.width;
	  		colocarImagenes();
	  	//}
	  }

	  function setTimerSlider(self){
		  clearInterval(interval_time);
		  interval_time = setInterval(function(){
			  nextimg(self);
		  },options.time_change_photo);
	  }
	  function previmg(element_single){
		  var widthactual = widthFinal;
		  //var leftult = element_single.children(".slide:eq(0)").position().left;
		  var num_fotos = element_single.children(".slide").length;
		  element_single.children(".slide:last").clone().prependTo(self).css({"left":(widthFinal*-2)+"px","opacity":"0.3"});
		  element_single.children(".slide:last").addClass("borrar_foto_slide");
		  var num_max = $('.slideButtons li a.'+options.active_class).index(".slideButtons li a");
		  element_single.children(".slide."+options.active_class).removeClass(options.active_class);
		  element_single.children(".slide:eq(1)").addClass(options.active_class);

		    if(self.parent().find('.caption').length>0){
				self.parent().find('.caption').removeClass("active");
				self.parent().find('.caption[data-num="'+element_single.children(".slide:eq(1)").attr("data-num")+'"]').addClass("active");
			}

		  for(var cont=0; cont<num_fotos;cont++) {
				var element_foto = self.children(".slide:eq("+cont+")");
				leftult = element_foto.position().left;
				left = leftult + widthactual;
				var str={"left":left+"px","opacity":options.opacity_inactive};
				if(element_foto.hasClass(options.active_class))
					str.opacity="1";
				element_foto.animate(str,700,function(){
					self.children(".slide.borrar_foto_slide").remove();
						//alert(self.children(".slide:eq(1)").position().left);
				});
			}
		  //alert(self.children(".slide:eq(1)").position().left);
		  siguiente_redonda(-1);
	  }
	  function siguiente_redonda(num){
		  var total_redondas = self.find('.slideButtons li.bullet').length;
		  var actual_red = (parseInt(self.find('.slideButtons li.bullet a.active').parent("li").attr("data-num"))-1)+num;
		  self.find('.slideButtons li.bullet a').removeClass("active");
		  if(actual_red<total_redondas)
			  self.find('.slideButtons li.bullet:eq('+((actual_red))+')').children("a").addClass("active");
		  else {
			  actual_red = actual_red - total_redondas;
			  self.find('.slideButtons li.bullet:eq('+actual_red+')').children("a").addClass("active");
		  }
	  }
	  function nextimg(element_single){
			var widthactual = widthFinal;
			var leftult = element_single.children(".slide:last").position().left;
			var num_fotos = element_single.children(".slide").length+1;
			element_single.children(".slide:eq(0)").clone().appendTo(self).before($('#buttons_carouselwe')).css({"left":(leftult+widthactual)+"px","opacity":"0.3"});
			element_single.children(".slide:eq(0)").addClass("borrar_foto_slide");
			var num_max = $('.slideButtons li.bullet a.'+options.active_class).index(".slideButtons li.bullet a");

			element_single.children(".slide."+options.active_class).removeClass(options.active_class);
			element_single.children(".slide:eq(2)").addClass(options.active_class);

			if(self.parent().find('.caption').length>0){
				self.parent().find('.caption').removeClass("active");
				self.parent().find('.caption[data-num="'+element_single.children(".slide:eq(2)").attr("data-num")+'"]').addClass("active");
			}

			for(var cont=0; cont<num_fotos;cont++) {
				var element_foto = self.children(".slide:eq("+cont+")");
				leftult = element_foto.position().left;
				left = leftult - widthactual;
				var str={"left":left+"px","opacity":options.opacity_inactive};
				if(element_foto.hasClass(options.active_class))
					str.opacity="1";
				element_foto.animate(str,700,function(){
					self.children(".slide.borrar_foto_slide").remove();
					
				});
			}

			
			
			
			siguiente_redonda(1);
		}

	  function nextximg(element_single,num){
		  var widthactual = widthFinal;
		  setTimerSlider(self);
			for(var cont=0;cont<num;cont++){
				var leftult = element_single.children(".slide:last").position().left;
				var num_fotos = element_single.children(".slide").length+1;
				element_single.children(".slide:eq("+cont+")").clone().appendTo(self).before($('#buttons_carouselwe')).css({"left":((leftult+widthactual))+"px","opacity":"0.3"});
				element_single.children(".slide:eq("+cont+")").addClass("borrar_foto_slide");
			}
			element_single.children(".slide.actual").removeClass(options.active_class);
			element_single.children(".slide:eq("+(num+1)+")").addClass(options.active_class);


			if(self.parent().find('.caption').length>0){
				self.parent().find('.caption').removeClass("active");
				self.parent().find('.caption[data-num="'+element_single.children(".slide:eq("+(num+1)+")").attr("data-num")+'"]').addClass("active");
			}

			for(var cont=0; cont<num_fotos;cont++) {
				var element_foto = self.children(".slide:eq("+cont+")");
				leftult = element_foto.position().left;
				left = leftult - (widthactual*num);
				var str={"left":left+"px","opacity":options.opacity_inactive};
				if(element_foto.hasClass(options.active_class))
					str.opacity="1";
				element_foto.animate(str,700,function(){
					self.children(".slide.borrar_foto_slide").remove();
				});
			}
		}
		function window_resize(){
			if(options.width_lg>0){
			  var width = $(window).width();
			  var medida_limite = 1200;
			  if(width>medida_limite){
			    options.width = options.width_lg;
			  }else{
			  	if(width<960){
			    	options.width = width;
			  	}else{
			    	options.width = options.width_md;
			    }
			  }
			}
		}
		$(window).resize(function(){
			  window_resize();
		});

  });
 };
})(jQuery);
